import { defineStore } from "pinia"
import { useNuxtApp } from "#app"
import { Comment, CommentCreationRequestPayload } from "@evercam/shared/types"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

interface CommentState {
  comments: Comment[]
  archivedComments: Comment[]
}

export const useCommentStore = defineStore({
  id: "comment",
  state: (): CommentState => ({
    comments: [] as Comment[],
    archivedComments: [] as Comment[],
  }),
  getters: {
    hasComments(state) {
      return state.comments.length > 0 || state.archivedComments.length > 0
    },
    hasActiveComments(state) {
      return state.comments.length > 0
    },
    isDisabled(): boolean {
      return !this.hasComments
    },
  },

  actions: {
    async init(project, scope) {
      this.fetchComments(project, scope)
    },
    async fetchComments(project, scope) {
      try {
        const { comments } = await EvercamApi.comments.fetchComments(project, {
          scope,
        })
        this.comments = comments.filter((comment) => !comment?.archived)
        this.archivedComments = comments.filter((comment) => comment?.archived)
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.comments.fetch_failed"
          ) as string,
          error,
        })
      }
    },
    async addComment(project, comment: CommentCreationRequestPayload) {
      try {
        const newComment = await EvercamApi.comments.addComment(
          project,
          comment
        )

        this.comments = [newComment, ...this.comments]
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.comments.create_success"
          ) as string
        )

        return newComment
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.comments.create_failed"
          ) as string,
          error,
        })
      }
    },
    async deleteComment(project, commentId) {
      try {
        await EvercamApi.comments.deleteComment(project, commentId)
        this.archivedComments = this.archivedComments.filter(
          (comment) => comment?.id !== commentId
        )
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.comments.delete_success"
          ) as string
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.comments.delete_failed"
          ) as string,
          error,
        })
      }
    },
    async archiveOrUnarchiveComment(project, commentId, archive) {
      try {
        const comment = await EvercamApi.comments.archiveOrUnarchiveComment(
          project,
          commentId,
          archive
        )
        if (archive) {
          this.comments = this.comments.filter(
            (comment) => comment?.id !== commentId
          )
          this.archivedComments.push(comment)
        } else {
          this.archivedComments = this.archivedComments.filter(
            (comment) => comment?.id !== commentId
          )
          this.comments.push(comment)
        }
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            `content.comments.${archive ? "archive" : "unarchive"}_success`
          ) as string
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            `content.comments.${archive ? "archive" : "unarchive"}_failed`
          ) as string,
          error,
        })
      }
    },
  },
})
